import { useState } from "react";
import Navbar from "./Navbar";
import { GiHamburgerMenu } from "react-icons/gi";
import { Outlet, Navigate } from "react-router-dom";

export default function Layout() {
  const [showNav, setShowNav] = useState(false);
  const isLoggedIn = localStorage.getItem("user")
  return isLoggedIn ? (
    <>
      <header>
        <GiHamburgerMenu onClick={() => setShowNav(!showNav)} />
      </header>
      <div >
        <Navbar show={showNav} />
      </div>
      <div>
        <Outlet />
      </div>
    </>
  ) : (
    <Navigate to="/login" replace />
  )
  
}
