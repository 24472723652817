import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import style from "./Login.module.scss";
import axios from "axios";
import { invalidCredentialToast } from "../../components/NotificationToast";
import { SyncOutlined } from "@ant-design/icons";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

const Login = () => {
  const initialValues = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (values) => {
    setShowSpinner(true);
    try {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("password", values.password);
      const response = await axios.post(
        "https://coldbank.lifebank.et/api/v1/login/adminmini",
        formData
      );
      
      // Check the response here and handle it accordingly
      if (response && response.data && response.data.ok === false) {
        // If the login is unsuccessful, set the login error message
        setLoginError(response.data.message);
        invalidCredentialToast();
        setShowSpinner(false);
      } else {
        setLoginError("");
        setShowSpinner(false);
        navigate("/");
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
    } catch (error) {
      // Handle any errors that occurred during the login process
      console.log("Login error:", error); // Log the error for debugging
      setLoginError("An error occurred during login. Please try again.");
      setShowSpinner(false);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.subContainer}>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form className={style.form}>
                <label className={style.label}>
                  Email Address
                  <Field
                    type="text"
                    name="email"
                    placeholder="Email"
                    className={style.input}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className={`${style.error} ${style.requiredError}`}
                  />
                </label>

                <label className={style.label}>
                  <div className={style.inputContainer}>
                    Password
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      className={style.input}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className={`$style.error} ${style.requiredError}`}
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className={style.passwordToggle}
                    >
                      {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                    </button>
                  </div>
                </label>
                <div className={style.buttonContainer}>
                  <button type="submit" className={style.button}>
                    {showSpinner ? <SyncOutlined spin /> : "LOGIN"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;

