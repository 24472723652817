import { FC, useEffect, useState } from "react";
import {
  Box,
  Text,
  Button,
  useDisclosure,
  Select
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import CustomModal from "./CustomModal";
import { somethingWentWrongToast } from "../components/NotificationToast";
import AddInfo from "./AddInfo";
import axios from "axios";

const SelectModal = ({ isOpen, onClose, onAddInfo }) => {
  const addInfo = useDisclosure();
  const [org, setOrg] = useState([]);
  const [id, setId] = useState("");

  useEffect(() => {
    getOrganization();
  }, []);

  const getOrganization = async () => {
    try {
      const response = await axios.get(
        "https://coldbank.lifebank.et/api/v1/mini_account/organization"
      );
      const responseData = response.data.data;
      setOrg(responseData);
    } catch (error) {
      console.log(error, "Error getting organization");
    }
  };

  const handleSubmit = (values, actions) => {
    if (id) {
      addInfo.onOpen();
    } else {
      somethingWentWrongToast();
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        showClose={true}
        closeButtonClick={onClose}
      >
        <Formik
          initialValues={{
            organization: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form style={{ width: "100%" }}>
              <Box
                display="flex"
                flexDirection="column"
                py="1rem"
                w="100%"
                justifyContent="flex-start"
                gap="0.625rem"
              >
                <Select
                  name="organization"
                  onChange={(e) => {
                    const selectedId = e.target.value;
                    setFieldValue("organization", selectedId);
                    setId(selectedId);
                  }}
                >
                  <option value="">Select Organization</option>
                  {org.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.organization}
                    </option>
                  ))}
                </Select>

                <Box display="flex" justifyContent="flex-end" gap="0.625rem">
                  <Button
                    borderRadius="0.625rem"
                    fontWeight={800}
                    fontSize="0.825rem"
                    color="#000000"
                    bg="white"
                    border="1px solid #E8E8E8"
                    _hover={{}}
                    type="button"
                    onClick={() => onClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    borderRadius="0.625rem"
                    bg="#000000"
                    fontWeight={800}
                    fontSize="0.825rem"
                    bgColor="#1E1E1E"
                    textColor="#fff"
                    _hover={{}}
                    type="submit"
                  >
                    Select
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </CustomModal>

      <AddInfo
        isOpen={addInfo.isOpen}
        onClose={addInfo.onClose}
        id={id}
        onAddInfo={onAddInfo}
      />
    </>
  );
};

export default SelectModal;

