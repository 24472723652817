import { FC } from "react";
import {
  Box,
  Text,
  Button,
  Input as InputComponent,
  useDisclosure,
} from "@chakra-ui/react";
import Input from "../components/Form/Input";
import CustomModal from "./CustomModal";
import { Form, Formik } from "formik";
import {
  somethingWentWrongToast,
  addSuccessful,
} from "../components/NotificationToast";
import axios from "axios";

const LocationModal = ({ isOpen, onClose, onAddInfo, organization_id }) => {
  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("address", values.address);
      formData.append("organization_id", values.organization_id);
      const response = await axios.post(
        "https://coldbank.lifebank.et/api/v1/mini_account/add/locations",
        formData
      );

      console.log("Data added successfully:", response.data);
      addSuccessful();
      onAddInfo(values);
      onClose();
    } catch (error) {
      somethingWentWrongToast();
    }
  };

  return (
    <>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        showClose={true}
        closeButtonClick={onClose}
      >
        <Formik
          initialValues={{
            address: "",
            organization_id: organization_id,
          }}
          onSubmit={handleSubmit}
        >
          {({}) => {
            return (
              <Form style={{ width: "100%" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  py="1rem"
                  w="100%"
                  justifyContent="flex-start"
                  gap="0.625rem"
                >
                  <Input name="address" type="text" placeholder="Address" />

                  {/* <Input
                    name="organization_id"
                    type="number"
                    value={organization_id}
                  /> */}

                  <Box display="flex" justifyContent="flex-end " gap="0.625rem">
                    <Button
                      borderRadius="0.625rem"
                      fontWeight={800}
                      fontSize="0.825rem"
                      color="#000000"
                      bg="white"
                      border="1px solid #E8E8E8"
                      _hover={{}}
                      type="button"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      borderRadius="0.625rem"
                      bg="#000000"
                      fontWeight={800}
                      fontSize="0.825rem"
                      bgColor="#1E1E1E"
                      textColor="#fff"
                      _hover={{}}
                      type="submit"
                      onClick={() => {}}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </CustomModal>
    </>
  );
};

export default LocationModal;
