import React, { useState, useEffect } from "react";
import style from "./Add.module.scss";
import { useDisclosure, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import OrgModal from "../../modal/OrgModal";
import LocationModal from "../../modal/LocationModal";
import Search from "../../components/Search";
import EditInfo from "../../modal/EditInfo";
import axios from "axios";

const AddOrganization = () => {
  const addOrg = useDisclosure();
  const location = useDisclosure();
  const [data, setData] = useState([]);
  console.log(data, "data");
  const [search, setSearch] = useState("");
  const [org, setOrg] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const getOrganization = async () => {
    try {
      const response = await axios.get(
        "https://coldbank.lifebank.et/api/v1/mini_account/organization"
      );
      const responseData = response.data.data;
      setOrg(responseData);
    } catch (error) {
      console.log(error, "Error getting organization");
    }
  };

  // Search handler
  useEffect(() => {
    setData(
      org?.filter((id) => {
        return Object.values(id).some((address) =>
          String(address).toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  }, [org, search]);

  const handleAddInfo = (newData) => {
    setData((prevData) => [...prevData, newData]);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    getOrganization();
  }, [refreshKey]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className={style.container}>
      <div>
        <div className={style.search}>
          <div className={style.add} onClick={() => addOrg.onOpen()}>
            Add Organization
          </div>
          <div>
            <Search placeholder="Find any order here" onChange={handleChange} />
          </div>
        </div>
        <div className={style.tableContainer}>
          <table className={style.table}>
            <thead>
              <tr>
                <th>Id</th>
                <th>Organization</th>
                <th>Trip Limits</th>
                <th>Payment Type</th>
                <th>Cost</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item) => (
                  <TableRow
                    key={item.id}
                    id={item.id}
                    organization={item.organization}
                    cost={item.cost}
                    payment={item.payment}
                    limits={item.limits}
                    onAddInfo={handleAddInfo}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan="6">No Organization</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <OrgModal
        isOpen={addOrg.isOpen}
        onClose={addOrg.onClose}
        onAddInfo={handleAddInfo}
      />
      <LocationModal isOpen={location.isOpen} onClose={location.onClose} />
    </div>
  );
};

export default AddOrganization;

const TableRow = ({ id, organization, cost, payment, limits, onAddInfo }) => {
  const navigate = useNavigate();
  const addInfo = useDisclosure();

  return (
    <tr>
      <td>{id}</td>
      <td>
       
          {organization}
       
      </td>

      <td>{limits}</td>
      <td>{payment === "null" ? "N/A" : payment}</td>
      <td>{cost === "null" ? "N/A" : cost}</td>
      <td>
        <Button
          borderRadius="0.625rem"
          m={"1rem"}
          fontWeight={800}
          fontSize="0.825rem"
          color="#fff"
          bg="#000000"
          border="1px solid #E8E8E8"
          _hover={{}}
          type="button"
          onClick={() => addInfo.onOpen()}
        >
          Edit
        </Button>

        <Button
          borderRadius="0.625rem"
          m={"1rem"}
          fontWeight={800}
          fontSize="0.825rem"
          color="#fff"
          bg="#000000"
          border="1px solid #E8E8E8"
          _hover={{}}
          type="button"
          onClick={() => navigate(`/add-location/${id}`)}
        >
          Add location
        </Button>
      </td>

      <EditInfo
        isOpen={addInfo.isOpen}
        onClose={addInfo.onClose}
        id={id}
        onAddInfo={onAddInfo}
      />
    </tr>
  );
};
