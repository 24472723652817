import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

export default function CustomModal({
  isOpen,
  onClose,
  children,
  size,
  title,
  footer,
  showClose,
  closeOnOverlayClick = true,
  closeButtonClick,
  bg,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
      size={size || "md"}
      isCentered
    >
      <ModalOverlay />

      <ModalContent
        borderRadius="4"
        p={{ base: "1rem", md: "2rem" }}
        maxHeight="95vh"
        overflowY="auto"
        bg={bg || "white"}
      >
        {title && (
          <ModalHeader px={2} fontSize="1.25rem" fontWeight={800}>
            {title}
          </ModalHeader>
        )}

        {showClose && (
          <ModalCloseButton
            onClick={() => (closeButtonClick ? closeButtonClick() : null)}
          />
        )}

        <ModalBody padding={0}>{children}</ModalBody>

        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
}
