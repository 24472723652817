import React, { useState, useEffect } from "react";
import style from "./Location.module.scss";
import { Button, Checkbox, useDisclosure } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import LocationModal from "../../modal/LocationModal";
import Search from "../../components/Search";
import { HiArrowNarrowLeft } from "react-icons/hi";
import axios from "axios";

const AddLocation = () => {
  let { id } = useParams();
//   console.log(id, "id");
  const location = useDisclosure();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [locate, setLocate] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate();
//   console.log("locate", locate);


  // Search handler
  useEffect(() => {
    setData(
      locate?.filter((id) => {
        return Object.values(id).some((address) =>
          String(address).toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  }, [locate, search]);

  const handleAddInfo = (newData) => {
    setData((prevData) => [...prevData, newData]);
    setRefreshKey((prevKey) => prevKey + 1);
  };



  useEffect(() => {
    try {
      const getLocation = async () => {
        const response = await axios.get(
          `https://coldbank.lifebank.et/api/v1/mini_account/locations/${id}`
        );
        setLocate(response.data.data);
      };
      getLocation();
    } catch (error) {
      console.log(error, "Error getting location");
    }
  }, [id, refreshKey]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const goBack = () => {
    navigate(`/add-organization`);
  };

  return (
    <>
        <div >
        
      </div>
    <div className={style.container}>
      <div style={{ display: "flex", gap: "8rem" }}>
      <HiArrowNarrowLeft className={style.purchaseOrderText} onClick={goBack} />
        <div className={style.add} onClick={() => location.onOpen()}>
        
          Add Location
        </div>
        <div className={style.search}>
          <Search placeholder="Find any order here" onChange={handleChange} />
        </div>
      </div>

      <div className={style.box}>
        {locate.length > 0 ? (
          <table className={style.table}>
            <thead>
              <tr className={style.headerrow}>
                <th>
                  Id
                </th>
                <th>Location</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr className={style.row} key={index}>
                  <td>
                    {item.id}
                  </td>
                  <td>{item.address === null ? "N/A" : item.address}</td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No Data</div>
        )}
      </div>

      <LocationModal
        isOpen={location.isOpen}
        onClose={location.onClose}
        onAddInfo={handleAddInfo}
        organization_id={id}
      />
    </div>
    </>
  );
};

export default AddLocation;
