import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Input as InputComponent,
  Select,
} from "@chakra-ui/react";
import Input from "../components/Form/Input";
import { Form, Formik, Field } from "formik";
import CustomModal from "./CustomModal";
import {
  somethingWentWrongToast,
  addSuccessful,
} from "../components/NotificationToast";
import axios from "axios";

const EditInfo = ({ isOpen, onClose, onAddInfo, id }) => {

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("organization", values.organization);
      formData.append("payment", values.payment);
      formData.append("cost", values.cost);
      formData.append("id", values.id);
      formData.append("limits", values.limits);

      const response = await axios.post(
        "https://coldbank.lifebank.et/api/v1/mini_account/edit/organization",
        formData
      );

      addSuccessful();
      onAddInfo(values);  // Trigger the parent component to reload data
      onClose();
    } catch (error) {
      somethingWentWrongToast();
    }
  };

  const types = [
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Yearly", value: "Yearly" },
  ];

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      showClose={true}
      closeButtonClick={onClose}
    >
      <Formik
        initialValues={{
          organization: "",
          payment: "",
          cost: "",
          id: id,
          limits: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ handleChange }) => {
          return (
            <Form style={{ width: "100%" }}>
              <Box
                display="flex"
                flexDirection="column"
                py="1rem"
                w="100%"
                justifyContent="flex-start"
                gap="0.625rem"
              >
                <Input name="organization" type="text" placeholder="Organization" />
                <Field as={Select} name="payment" placeholder="Payment" onChange={handleChange}>
                  {types.map((item) => (
                    <option key={item.value}>{item.value}</option>
                  ))}
                </Field>
                <Input name="cost" type="number" placeholder="Cost" />
                {/* <Input name="id" type="text" placeholder={id} disabled={true} /> */}
                <Input name="limits" type="number" placeholder="Trip Limits" />

                <Box display="flex" justifyContent="flex-end" gap="0.625rem">
                  <Button
                    borderRadius="0.625rem"
                    fontWeight={800}
                    fontSize="0.825rem"
                    color="#000000"
                    bg="white"
                    border="1px solid #E8E8E8"
                    _hover={{}}
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    borderRadius="0.625rem"
                    bg="#000000"
                    fontWeight={800}
                    fontSize="0.825rem"
                    bgColor="#1E1E1E"
                    textColor="#fff"
                    _hover={{}}
                    type="submit"
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default EditInfo;
