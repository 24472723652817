import {
  Box,
  Flex,
  Text,
  Image,
  Button,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Table,
  TableContainer,
  useDisclosure,
} from "@chakra-ui/react";
import SelectModal from "../../modal/SelectModal";
import axios from "axios";
import { useEffect, useState } from "react";
import { constructOrderDate } from "../../components/helper/DateFormatter";

export default function TableUI() {
  const selectModal = useDisclosure();
  const [data, setData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const headerStyle = {
    fontSize: "12px",
    color: "#c3c3c3",
    fontWeight: "bold",
  };

  const dataStyle = { fontSize: "14px", color: "#1e1e1e", fontWeight: "bold" };

  const getData = async () => {
    setShowSpinner(true);
    try {
      const response = await axios.get(
        "https://coldbank.lifebank.et/api/v1/mini_account/fetch"
      );
      setData(response.data.data);
      setShowSpinner(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddInfo = (newData) => {
    setData((prevData) => [...prevData, newData]);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    getData();
  }, [refreshKey]);

  return (
    <Box padding="1rem">
      <Box className="mt-[1.5rem]">
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Image src="" width={"200px"} />
            {/* <Text style={{ fontWeight: "bold" }}>
              In the business of saving lives
            </Text> */}
          </Box>
          <Button
            style={{ backgroundColor: "#00161f", color: "white" }}
            onClick={() => selectModal.onOpen()}
          >
            Create Trip
          </Button>
        </Flex>

        <Box>
          <TableContainer>
            <Table variant="striped" colorScheme="gray">
              <Thead className="text-[#c3c3c3]">
                <Tr>
                  <Th sx={headerStyle}>No.</Th>
                  <Th sx={headerStyle}>DATE</Th>
                  <Th sx={headerStyle}>PICK UP AND DROP OFF LOCATION</Th>
                  <Th sx={headerStyle}>PICK UP TIME</Th>
                  <Th sx={headerStyle}>DROP OFF TIME</Th>
                  <Th sx={headerStyle}>PRICE(ETB)</Th>
                  <Th sx={headerStyle}>NO. OF SAMPLES</Th>
                  {/* <Th sx={headerStyle}>KM</Th> */}
                </Tr>
              </Thead>

              <Tbody>
                {data.length > 0 ? (
                  data.map((row, index) => (
                    <Tr key={index}>
                      <Td sx={dataStyle}>{row.id}</Td>
                      <Td sx={dataStyle}>{constructOrderDate(row?.created)}</Td>
                      <Td sx={dataStyle}>
                        {row.picklocation} / {row.droplocation}
                      </Td>
                      <Td sx={dataStyle}>{row.picktime}</Td>
                      <Td sx={dataStyle}>{row.droptime}</Td>
                      <Td sx={dataStyle}>{row.amount}</Td>
                      <Td sx={dataStyle}>{row.numberofsample}</Td>
                      {/* <Td sx={dataStyle}>{row.time_difference_hours}</Td> */}
                    </Tr>
                  ))
                ) : (
                  <Flex fontSize="14px">
                    {showSpinner ? "Loading..." : "Items Not Available"}
                  </Flex>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <SelectModal
        isOpen={selectModal.isOpen}
        onClose={selectModal.onClose}
        onAddInfo={handleAddInfo}
      />
    </Box>
  );
}

