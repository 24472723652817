import { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import TableUI from "./pages/DataTable/Table";
import Login from "./pages/Login/Login";
import { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { toast, ToastBar, Toaster } from "react-hot-toast";
import Layout from "./components/Layout";
import AddOrganization from "./pages/AddOrganization/AddOrganization";
import AddLocation from "./pages/AddLocation/AddLocation";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import { generateToken, messaging } from "./notifications/firebase";
import { onMessage } from "firebase/messaging";

function App() {
  useEffect(() => { 
    generateToken();
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      toast(payload.notification.body);
    })
   }, []);
  return (
    <>
      <Suspense>
        <ChakraProvider>
          <Toaster
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
            }}
          >
            {(t) => (
              <ToastBar toast={t}>
                {({ icon, message }) => (
                  <>
                    {icon}
                    {message}
                    {t.type !== "loading" && (
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={() => toast.dismiss(t.id)}
                      ></div>
                    )}
                  </>
                )}
              </ToastBar>
            )}
          </Toaster>
          <Router>
            <Routes>
              {/* Redirect from root to login */}
              {/* <Route exact path="/" element={<Navigate to="/login" />} /> */}

              {/* Main routes */}
              <Route>
                <Route path="/" element={<Layout />}>
                  <Route index element={<TableUI />} />
                  <Route path="/add-organization" element={<AddOrganization />} />
                  <Route path="/add-location/:id" element={<AddLocation />} />
                  <Route path="/reset-password" element={<ForgotPassword />} />
                </Route>
              </Route>

              {/* Login route */}
              <Route path="/login" element={<Login />} />
            </Routes>
          </Router>
        </ChakraProvider>
      </Suspense>
    </>
  );
}

export default App;
