import toast from "react-hot-toast";

// @Authentication
export const loginSuccessToast = () => toast.success("Login Successfully");
export const invalidCredentialToast = () => toast.error("Invalid Credentials");
export const passwordChangedSuccessToast = () =>
  toast.success(
    `Password Updated Successfully.
    Sign in with your new password`
  );

export const passwordChangedWarningToast = () =>
  toast.error(`Invalid old password`);

export const somethingWentWrongToast = () =>
  toast.error("You can't add an empty data");

export const addSuccessful = () => toast.success("Data added successfully");
