import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input as InputComponent,
} from "@chakra-ui/react";
import { useField } from "formik";

export default function Input(props) {
  const [field, meta] = useField(props.name);
  return (
    <FormControl
      w="100%"
      borderRadius="0.625rem"
      isInvalid={!!meta.error && meta.touched}
    >
      {props.label && (
        <FormLabel fontSize="0.875rem" fontWeight={600}>
          {props.label}
        </FormLabel>
      )}
      <InputComponent
        {...field}
        {...props}
        h="3.5rem"
        bgColor="#F5F5F5"
        border="none"
        padding="0.5rem 1rem"
        _placeholder={{
          color: "#8D8C8C",
          fontSize: "0.75rem",
        }}
        fontSize="0.75rem"
        className="input"
      />
      {meta.error && meta.touched && (
        <FormHelperText
          textAlign="left"
          color="red.400"
          fontSize={{ base: "0.75rem", md: "0.875rem" }}
          mt={"0.25rem"}
          mb={"1rem"}
        >
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}
