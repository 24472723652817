import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import style from "../Login/Login.module.scss";
import { SyncOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  passwordChangedSuccessToast,
  passwordChangedWarningToast,
} from "../../components/NotificationToast";

const validationSchema = Yup.object().shape({
  newpassword: Yup.string().required("Required"),
});

const ForgotPassword = () => {
  const initialValues = {
    oldpassword: "",
    newpassword: "",
    id: "",
  };

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [loginError, setLoginError] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (values) => {
    setShowSpinner(true);
    try {
      const formData = new FormData();
      formData.append("oldpassword", values.oldpassword);
      formData.append("newpassword", values.newpassword);
      formData.append("id", values.id);
      const response = await axios.post(
        "https://coldbank.lifebank.et/api/v1/login/adminmini/reset/password",
        formData
      );

      // Check the response here and handle it accordingly
      if (response && response.data && response.data.ok === false) {
        // If the login is unsuccessful, set the login error message
        setLoginError(response.data.message);
        setShowSpinner(false);
        passwordChangedWarningToast();
      } else {
        // If the login is successful, clear the login error message
        setLoginError("");
        setShowSpinner(false);
        passwordChangedSuccessToast();
        window.localStorage.setItem("user", JSON.stringify(values));
        navigate("/login"); // Navigate to the homepage after successful login
      }
    } catch (error) {
      // Handle any errors that occurred during the login process
      console.log("Login error:", error); // Log the error for debugging
      setLoginError("An error occurred during login. Please try again.");
    }
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.subContainer}>
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({}) => (
                <Form className={style.form}>
                  <label className={style.label}>
                    Old Password
                    <Field
                      type="text"
                      name="oldpassword"
                      placeholder="Old Password"
                      className={style.input}
                    />
                    <ErrorMessage
                      name="oldpassword"
                      component="div"
                      className={`${style.error} ${style.requiredError}`}
                    />
                  </label>

                  <label className={style.label}>
                    <div className={style.inputContainer}>
                      New Password
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="newpassword"
                        placeholder="Confirm Password"
                        className={style.input}
                      />
                      <ErrorMessage
                        name="newpassword"
                        component="div"
                        className={`$style.error} ${style.requiredError}`}
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className={style.passwordToggle}
                      >
                        {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                      </button>
                    </div>
                  </label>
                  <label className={style.label}>
                    <div className={style.inputContainer}>
                      Enter ID
                      <Field
                        type="number"
                        name="id"
                        placeholder="ID Number"
                        className={style.input}
                      />
                      <ErrorMessage
                        name="id"
                        component="div"
                        className={`$style.error} ${style.requiredError}`}
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className={style.passwordToggle}
                      >
                        {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                      </button>
                    </div>
                  </label>
                  <div className={style.buttonContainer}>
                    <button type="submit" className={style.button}>
                      {showSpinner ? <SyncOutlined spin /> : "Reset Password"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
