import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Select,
} from "@chakra-ui/react";
import { Form, Formik, Field } from "formik";
import CustomModal from "./CustomModal";
import {
  somethingWentWrongToast,
  addSuccessful,
} from "../components/NotificationToast";
import Input from "../components/Form/Input";
import axios from "axios";

const AddInfo = ({ isOpen, onClose, onAddInfo, id }) => {
  const [organizations, setOrganizations] = useState([]);

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("pick_location", values.pick_location);
      formData.append("drop_location", values.drop_location);
      formData.append("pick_time", values.pick_time);
      formData.append("drop_time", values.drop_time);
      formData.append("number_of_sample", values.number_of_sample);
      formData.append("organization_id", values.organization_id);

      const response = await axios.post(
        "https://coldbank.lifebank.et/api/v1/mini_account/add",
        formData
      );

      addSuccessful();
      onAddInfo(values);
      onClose();
    } catch (error) {
      somethingWentWrongToast();
    }
  };

  const fetchOrganizations = async () => {
    if (id) {
      try {
        const response = await axios.get(
          `https://coldbank.lifebank.et/api/v1/mini_account/organization/locations/` + id
        );
        setOrganizations(response.data.data);
      } catch (error) {
        console.log(error, "Error getting organization");
      }
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, [id]);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      showClose={true}
      closeButtonClick={onClose}
    >
      <Formik
        initialValues={{
          pick_location: "",
          drop_location: "",
          pick_time: "",
          drop_time: "",
          number_of_sample: "",
          organization_id: id,
        }}
        onSubmit={handleSubmit}
      >
        {({ handleChange }) => (
          <Form style={{ width: "100%" }}>
            <Box
              display="flex"
              flexDirection="column"
              py="1rem"
              w="100%"
              justifyContent="flex-start"
              gap="0.625rem"
            >
              <Field
                as={Select}
                name="pick_location"
                placeholder="Select pick location"
                onChange={handleChange}
              >
                {organizations.map((item) => (
                  <option key={item.id} value={item.address}>
                    {item.address}
                  </option>
                ))}
              </Field>

              <Field
                as={Select}
                name="drop_location"
                placeholder="Select drop location"
                onChange={handleChange}
              >
                {organizations.map((item) => (
                  <option key={item.id} value={item.address}>
                    {item.address}
                  </option>
                ))}
              </Field>

              <Input
                label="Pick Time"
                name="pick_time"
                type="time"
                placeholder="10:00"
              />

              <Input
                label="Drop Time"
                name="drop_time"
                type="time"
                placeholder="12:00"
              />
              <Input
                label="No. of samples"
                name="number_of_sample"
                type="number"
                placeholder="30"
              />

              <Box display="flex" justifyContent="flex-end " gap="0.625rem">
                <Button
                  borderRadius="0.625rem"
                  fontWeight={800}
                  fontSize="0.825rem"
                  color="#000000"
                  bg="white"
                  border="1px solid #E8E8E8"
                  _hover={{}}
                  type="button"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
                <Button
                  borderRadius="0.625rem"
                  bg="#000000"
                  fontWeight={800}
                  fontSize="0.825rem"
                  bgColor="#1E1E1E"
                  textColor="#fff"
                  _hover={{}}
                  type="submit"
                >
                  Done
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddInfo;


