import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import axios from "axios";


// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDfIqc68hdrR_t0lC4ycOM0PQxegdiD6Tc",
  authDomain: "onebox-5a1c3.firebaseapp.com",
  projectId: "onebox-5a1c3",
  storageBucket: "onebox-5a1c3.appspot.com",
  messagingSenderId: "367791288128",
  appId: "1:367791288128:web:381719c233c1a29dd394a5",
  measurementId: "G-DZ2DRCNV8F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    console.log("permission", permission);

    if (permission === "granted") {
        try {
            const token = await getToken(messaging, {
                vapidKey: "BCsLnHXvtA2FZwh2BNyKvRzLvLL2QA2AngWCrp3JOy75mHrXDsvuS9TtAhOBNqdjhHjkjrya3a3KZi17HsHFKFg"
            });
            console.log("token", token);
            const user = JSON.parse(localStorage.getItem("user"));
            console.log("userData", user);

            try {
                const response = await axios.post("https://coldbank.lifebank.et/api/v1/mini_account/add/token", {
                    user_id: user?.id,
                    token: token
                }
                );
                console.log(response.data);
            } catch (error) {
                console.log('Error sending token to backend', error);
            }
            return token;
        } catch (error) {
            console.log("An error occurred while retrieving token. ", error);
        }
    } else {
        console.log("No permission");
    }
}

export { messaging };