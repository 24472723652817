import React from "react";
import "./nav.scss";
import { Link } from "react-router-dom";

const Navbar = ({ show }) => {
  return (
    <div className={show ? "sidenav active" : "sidenav"}>
      {/* <img src={logo} alt="logo" className='logo'/> */}
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>

        <li>
          <Link to="/add-organization">Add Organization</Link>
        </li>

        <li>
          <Link to="/reset-password">Reset-Password</Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
